import React, { useEffect, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./SmartHomepage.scss";
import { useSelector } from "react-redux";
import NomadsFetchRequests from "../../requests";

import SubImageDashboard from "../Common/ImageDashboard/SubImageDashboard";
import { useDispatch } from "react-redux";
import { setError } from "../../store/actions/errors";
import SubLoadingButton from "../Common/Loading/LoadingButton/SubLoadingButton";
import { translations } from "../../translations/texts";
import { removeError } from "../../store/actions/errors";
import { getToken } from "../../utilities/cookie";
import SubComponentLoading from "../Common/Loading/ComponentLoading/SubComponentLoading";

const placeholderImages = ["https://placehold.co/600x400", "https://placehold.co/600x400", "https://placehold.co/600x400", "https://placehold.co/600x400"];

const categories = ["views", "likes"];
// const subCategories = ["retro", "Realism", "Landscape", "Abstract"];

const SmartHomepage = () => {
	const [selectedImageId, setSelectedImageId] = useState(null);
	const [subCategories, setSubCategories] = useState([]);
	const [category, setCategory] = useState(subCategories[0]);
	const [categoryIndex, setCategoryIndex] = useState(0);
	const [subCategoryIndex, setSubCategoryIndex] = useState(0);
	const [sort, setSort] = useState([]);
	const [page, setPage] = useState(1);
	const [liked, setLiked] = useState(null);
	const [componentLoading, setComponentLoading] = useState(true);
	const [generatedImages, setGeneratedImages] = useState([]);

	const dispatch = useDispatch();

	const selectedLanguage = useSelector((state) => state.language.selectedLanguage);
	const isLoggedIn = useSelector((state) => !!state.user.userData.id);

	useEffect(() => {
		setComponentLoading(true);
		if (isLoggedIn) {
			Promise.all([
				NomadsFetchRequests.nomadsAi.getAllScenesSorted(sort, page, category),
				NomadsFetchRequests.nomadsAi.getLikes(),
				NomadsFetchRequests.nomadsAi.getCategories(),
			])
				.then(([scenesResponse, likesResponse, categoriesResponse]) => {
					setGeneratedImages(scenesResponse.data.scenes.slice(0, 50));
					setLiked(likesResponse.data.likes);
					setSubCategories(categoriesResponse.data.categories);
				})
				.catch((error) => {
					dispatch(setError(error));
				})
				.finally(() => {
					setComponentLoading(false);
				});
		} else {
			Promise.all([
				NomadsFetchRequests.nomadsAi.getAllScenesSorted(sort, page, category),
				NomadsFetchRequests.nomadsAi.getCategories(),
			])
				.then(([scenesResponse, categoriesResponse]) => {
					setGeneratedImages(scenesResponse.data.scenes.slice(0, 50));
					setSubCategories(categoriesResponse.data.categories);
				})
				.catch((error) => {
					dispatch(setError(error));
				})
				.finally(() => {
					setComponentLoading(false);
				});
		}
		return () => {
			dispatch(removeError);
		};
	}, [sort, category, page]);

	const changeDataCategory = (name, index) => {
		setGeneratedImages([]);
		setSort(name);
		setCategoryIndex(index);
	};

	const changeDataSubCategory = (name, index) => {
		setSubCategoryIndex(index);
		setCategory(name);
	};

	const changePage = (control) => {
		if (control === "up") {
			setPage((prevPage) => prevPage + 1);
		} else if (control === "down") {
			setPage((prevPage) => (prevPage > 1 ? prevPage - 1 : prevPage));
		}
	};

	const handleImageSelect = (sceneId) => {
		setSelectedImageId(sceneId);
	};

	return (
		<section data-testid="container-homepage">
			<div className="category-tabs">
				{categories.map((name, index) => (
					<button key={index} className={index === categoryIndex ? "active" : ""} onClick={() => changeDataCategory(name, index)}>
						{name}
					</button>
				))}
			</div>

			{/* <div className="subcategory-slider">
				{subCategories.map((data, index) => (
					<div key={index} className={`subcategory ${index === subCategoryIndex ? "active" : ""}`} background={data.posterSrc} onClick={() => changeDataSubCategory(data.name, index)}>
						{data.name}
					</div>
				))}
			</div> */}

			<div className="subcategory-slider">
				{subCategories.map((data, index) => (
					<div
						key={index}
						className={`subcategory ${index === subCategoryIndex ? "active" : ""}`}
						onClick={() => changeDataSubCategory(data.name, index)}
						// style={{  
						// 	backgroundImage: `url(${data.posterSrc})`,
						// 	backgroundPosition: 'center',
						// 	backgroundSize: 'cover',
						// 	backgroundRepeat: 'no-repeat'
						//   }}
					>
						<span className="subcategory-name">{data.name}</span>
					</div>
				))}
			</div>

			<div className="pagination-controls">
				<button onClick={() => changePage("down")}>←</button>
				<span>
					{" "}
					{translations[selectedLanguage].homepage.page} {page}{" "}
				</span>
				<button onClick={() => changePage("up")}>→</button>
			</div>
			<div className="rentals-dashboard">
				{componentLoading ? (
					<SubComponentLoading />
				) : (
					<div className="rentals-dashboard-rentals">
						{generatedImages.slice(0, 50).map((rental, index) => {
							let formattedDate;

							if (rental.insertDate) {
								formattedDate = rental.insertDate.split("T")[0];
							}

							const isLiked = liked && liked.some((likedItem) => likedItem.sceneId === rental.sceneId);

							return (
								<div
									key={index}
									className={`rentals-dashboard-rental ${selectedImageId === rental.sceneId ? "isSelected" : ""}`}
									onClick={() => handleImageSelect(rental.sceneId)}
								>
									<SubImageDashboard
										thumbNail={rental.thumbSrc}
										img={rental.imageSrc}
										isSelected={selectedImageId === rental.sceneId}
										id={rental.sceneId}
										createdOn={formattedDate}
										prompt={rental.prompt}
										audioSrc={rental.audioSrc}
										favorite={rental.favourite}
										like={isLiked}
										views={rental.viewCount}
									/>
								</div>
							);
						})}
					</div>
				)}
			</div>
		</section>
	);
};

export default SmartHomepage;
