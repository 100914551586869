import axios from "axios";
import { errorHandler } from "../utilities/errors";
import { getHeader, getHeaderAI } from "../utilities/token";
import { getToken } from "../utilities/cookie";

const nomadsAi = {
	getTokenPackages: async () => {
        return await axios
        .get(
            `${process.env.REACT_APP_SERVER_HOST_AI}/dev/gettokenpackages`,
            getHeaderAI()
        )
        .catch(errorHandler);
	},

	buyTokens: async (token, tokenId, dataKey, amount) => {
		return await axios
			.post(
				`${process.env.REACT_APP_SERVER_HOST_AI}/dev/buytokens`,
				{
					tokensId: tokenId,
					dataKey: dataKey,
					amount: amount
				},
				getHeaderAI(token)
			)
			.catch(errorHandler);
	},

    getAllScenes: async (token = getToken()) => {
        return await axios
        .get(
            `${process.env.REACT_APP_SERVER_HOST_AI}/dev/getallscenes?sort=${sort}&page=${page}`,
            getHeaderAI(token)
        )
        .catch(errorHandler);
	},
	
    getAllScenesSorted: async (sort, page, category, token = getToken()) => {
        return await axios
        .get(
            `${process.env.REACT_APP_SERVER_HOST_AI}/dev/getallscenes?sort=${sort}&page=${page}&category=${category}`,
            getHeaderAI(token)
        )
        .catch(errorHandler);
	},

    getCategories: async ( token = getToken()) => {
        return await axios
        .get(
            `${process.env.REACT_APP_SERVER_HOST_AI}/dev/getallcategories`,
            getHeaderAI(token)
        )
        .catch(errorHandler);
	},

	getUserScenes: async (token) => {
        return await axios
        .get(
            `${process.env.REACT_APP_SERVER_HOST_AI}/dev/getalluserscenes`,
            getHeaderAI(token)
        )
        .catch(errorHandler);
	},

	getUserSceneById: async (token) => {
        return await axios
        .get(
            `https://kldwqrotd1.execute-api.us-east-1.amazonaws.com/dev/getuserscene`,
            getHeaderAI(token)
        )
        .catch(errorHandler);
	},

	getTokenAmount: async (token) => {
        return await axios
        .get(
            `${process.env.REACT_APP_SERVER_HOST_AI}/dev/getusertokens`,
            getHeaderAI(token)
        )
        .catch(errorHandler);
	},

	shareLink: async (id) => {
        return await axios
        .post(
            `${process.env.REACT_APP_SERVER_HOST_AI}/dev/sharelink`,
            {
                sceneId: id
            },
            getHeaderAI(),


        )
        .catch(errorHandler);
	},

    addFavorite: async (id, token = getToken()) => {
        return await axios
        .post(
            `${process.env.REACT_APP_SERVER_HOST_AI}/dev/addfavourite`,
            {
                sceneId: id
            },
            getHeaderAI(token),
        )
        .catch(errorHandler);
	},

    removeFavorite: async (id, token = getToken()) => {
        return await axios
        .post(
            `${process.env.REACT_APP_SERVER_HOST_AI}/dev/removefavourite`,
            {
                sceneId: id
            },
            getHeaderAI(token),
        )
        .catch(errorHandler);
	},

    getGeneration: async (id) => {
        return await axios
        .post(
            `${process.env.REACT_APP_SERVER_HOST_AI}/dev/getgeneration`,
            {
                sceneId: id
            },
            getHeaderAI(),
            
        )
        .catch(errorHandler);
	},
    
    getFavorites: async (token) => {
        return await axios
        .get(
            `${process.env.REACT_APP_SERVER_HOST_AI}/dev/getfavourites`,
            getHeaderAI(token)
        )
        .catch(errorHandler);
	},

	getLikes: async (token = getToken()) => {
        return await axios
        .get(
            `${process.env.REACT_APP_SERVER_HOST_AI}/dev/getlikes`,
            getHeaderAI(token)
        )
        .catch(errorHandler);
	},

    addLike: async (id, token = getToken()) => {
        return await axios
        .post(
            `${process.env.REACT_APP_SERVER_HOST_AI}/dev/addlike`,
            {
                sceneId: id,
            },
            getHeaderAI(token)
        )
        .catch(errorHandler);
	},

    
	removeLike: async (id, token = getToken()) => {
        return await axios
        .post(
            `${process.env.REACT_APP_SERVER_HOST_AI}/dev/removelike`,
            {
                sceneId: id,
            },
            getHeaderAI(token)
        )
        .catch(errorHandler);
	},

    getViewById: async (id, token = getToken()) => {
        return await axios
        .post(
            `${process.env.REACT_APP_SERVER_HOST_AI}/dev/getviewbyid`,
            {
                sceneId: id,
            },
            getHeaderAI(token)
        )
        .catch(errorHandler);
	},

    addView: async (id, token = getToken()) => {
        return await axios
        .post(
            `${process.env.REACT_APP_SERVER_HOST_AI}/dev/addview`,
            {
                sceneId: id,
            },
            getHeaderAI(token)
        )
        .catch(errorHandler);
	},
};

export default nomadsAi;
