import React, { useState, useRef, useEffect } from "react";
import { ASSETS_HOST } from "../../../constants/constants";
import "./SubImageDashboard.scss";
import { translations } from "../../../translations/texts";
import { useSelector } from "react-redux";
import { FaDownload, FaExpand, FaHeart, FaShare } from "react-icons/fa";
import { saveAs } from "file-saver";
import NomadsFetchRequests from "../../../requests";
import Alert from "@mui/material/Alert";
import { ASSETS_PATH } from "../../../constants/constants";
import { useNavigate } from "react-router-dom";
import { INTERNAL_ROUTES } from "../../../constants/internalRoutes";
import ReactPannellum from "react-pannellum";
//WARNING this page works with a lot of different libraries, code will not be the cleanest so be aware of that
import { getToken } from "../../../utilities/cookie";
import { Watermark } from "antd";

const SubImageDashboard = ({ audioSrc, prompt, createdOn, img, thumbNail, isSelected, id, favorite, startFullscreen, like, views, freetier }) => {
	const [success, setSuccess] = useState(false);
	const [clipboard, setClipboard] = useState(null);
	const [isFavorite, setIsFavorite] = useState(favorite);
	const [isIphone, setIsIphone] = useState(false);
	const [fullscreen, setFullscreen] = useState(startFullscreen);
	const [isReadOnly, setIsReadOnly] = useState(false);
	const [initialLike, setInitialLike] = useState(like);
	const [device, setDevice] = useState(null);
	const [allowDownloadButton, setAllowDownloadButton] = useState(null);
	const [showPopUp, setShowPopUp] = useState(false);
	const [view, setView] = useState(views);
	const [freeTier, setFreeTier] = useState(false);

	const isLoggedIn = useSelector((state) => !!state.user.userData.id);
	const selectedLanguage = useSelector((state) => state.language.selectedLanguage);

	if (freetier) {
		setFreeTier(true);
	}

	if (!views && typeof views !== "number") {
		NomadsFetchRequests.nomadsAi
			.getViewById(id)
			.then((response) => {
				setView(response.data.views.views);
			})
			.catch((error) => {
				dispatch(setError(error));
			});
	}

	useEffect(() => {
		const userAgent = navigator.userAgent || navigator.vendor || window.opera;

		if (/iPhone/i.test(userAgent)) {
			setIsIphone(true);
			setDevice("apple");
			// setDevice("https://www.apple.com/ca/app-store/");
		} else if (/Android/i.test(userAgent)) {
			setDevice("android");
			// setDevice("https://play.google.com/store/games?hl=en");
		} else if (/Oculus/i.test(userAgent)) {
			setDevice("oculus");
			// setDevice("https://www.meta.com/experiences/");
		} else if (/iPad/i.test(userAgent)) {
			setDevice("apple");
			// setDevice("https://www.apple.com/ca/app-store/");
		} else {
			setDevice("computer");
		}

		if (isLoggedIn && isSelected) {
			Promise.all([NomadsFetchRequests.nomadsAi.addView(id)])
			.then(([]) => {

			})
			.catch((error) => {
				console.error("Failed to fetch", error);
			});
		}
		
	}, []);

	const navigate = useNavigate();

	const handleContentClick = (event) => {
		event.stopPropagation();
	};

	const downloadImage = () => {
		if (isLoggedIn) {
			saveAs(img, "threeSixtImage.jpg");
		} else {
			navigate(INTERNAL_ROUTES.USER.SIGN_IN);
		}
	};

	const shareImage = async () => {
		const response = await NomadsFetchRequests.nomadsAi.shareLink(id);

		if (response.data.code === 200) {
			setSuccess(true);
			const link = response.data.link.replace(/^"+|"+$/g, "");
			setClipboard(link);
			navigator.clipboard.writeText(link);
		}

		setTimeout(() => {
			setSuccess(false);
		}, 10000);
	};

	const favoriteImage = async () => {
		if (!isLoggedIn) {
			navigate(INTERNAL_ROUTES.USER.SIGN_IN);
			return;
		}

		try {
			let response;
			if (isFavorite) {
				response = await NomadsFetchRequests.nomadsAi.removeFavorite(id);
			} else {
				response = await NomadsFetchRequests.nomadsAi.addFavorite(id);
			}
			if (response.data.code === 200) {
				setIsFavorite(!isFavorite);
			}
		} catch (error) {
			console.error("Failed to update favorite status:", error);
		}
	};

	const fullscreenImage = () => {
		setFullscreen(!fullscreen);
	};

	const config = {
		autoLoad: true,
	};


	const handleLikes = async () => {
		if (!isLoggedIn) {
			navigate(INTERNAL_ROUTES.USER.SIGN_IN);
			return;
		}

		try {
			let response;
			if (initialLike) {
				response = await NomadsFetchRequests.nomadsAi.removeLike(id);
			} else {
				response = await NomadsFetchRequests.nomadsAi.addLike(id);
			}
			if (response.data.code === 200) {
				setInitialLike(!initialLike);
			}
		} catch (error) {
			console.error("Failed to update favorite status:", error);
		}
	};

	const callToAction = () => {
		if (device === "apple") {
			window.location.href = "https://www.apple.com/ca/app-store/";
		} else if (device === "android") {
			window.location.href = "https://play.google.com/store";
		} else if (device === "oculus") {
			window.location.href = "https://www.meta.com/experiences/";
		} else {
			setShowPopUp(true);
		}
	};

	return (
		<section data-testid="container-rental" className={`rental ${isSelected ? "isSelected" : ""}`} id={id}>
			<div className={`menu ${isSelected ? "isSelected" : ""}`}>
				{isSelected ? (
					freeTier ? (
						<Watermark content="Nomads AI" font={{ color: "white" }}>
							<div className={fullscreen ? "fullscreen-for-iphone" : "not-fullscreen-for-iphone"}>
								<ReactPannellum id="1" sceneId="firstScene" config={config} imageSource={img} />
							</div>
						</Watermark>
					) : (
						<div className={fullscreen ? "fullscreen-for-iphone" : "not-fullscreen-for-iphone"}>
							<ReactPannellum id="1" sceneId="firstScene" config={config} imageSource={img} />
						</div>
					)
				) : (
					<img src={thumbNail} alt="Rental Poster" />
				)}

				<div className="fixed-height">
					{isSelected && (
						<div className="aframe-container">
							<div>
								{(isIphone || fullscreen) && (
									<button onClick={fullscreenImage} className={fullscreen ? "seperate-buttons very-high-z-index" : "seperate-buttons"}>
										<FaExpand size="1.5em" />
									</button>
								)}

								<button onClick={shareImage} className="seperate-buttons" id="share-image">
									<FaShare />
									<b className="seperate-buttons">{translations[selectedLanguage].subImageDashboard.share}</b>
								</button>
								<button onClick={downloadImage} className="seperate-buttons" id="download-button">
									<FaDownload />
								</button>
								<button onClick={favoriteImage} className="seperate-buttons" id="favorite-image">
									{isFavorite ? (
										<img src={ASSETS_PATH.FAVORITE.IS_FAVORITE} className="favorite-image" alt="Favorite" />
									) : (
										<img src={ASSETS_PATH.FAVORITE.IS_NOT_FAVORITE} className="favorite-image" alt="Not Favorite" />
									)}
								</button>
								<button onClick={handleLikes} className={initialLike ? "seperate-buttons liked" : "seperate-buttons notLiked"} id="like-button">
									<b>{translations[selectedLanguage].subImageDashboard.like}</b>
								</button>
								
							</div>
							<div onClick={callToAction} className={fullscreen ? "call-to-action-fullscreen" : "call-to-action"} id="call-to-action">
								<div className="call-to-action-buttons">
									<img
										src={
											device === "apple"
												? ASSETS_PATH.STORE[`APP_STORE_${selectedLanguage}`]
												: device === "android"
												? ASSETS_PATH.STORE[`PLAY_STORE_${selectedLanguage}`]
												: device === "oculus"
												? ASSETS_PATH.STORE[`META_STORE_${selectedLanguage}`]
												: ASSETS_PATH.STORE[`DOWNLOAD_BUTTON_${selectedLanguage}`]
										}
										alt="App Store"
									/>
								</div>
							</div>
						</div>
					)}

					{showPopUp ? (
						<div className="window" id="callToActionBanner">
							<img className="window-logo" src={ASSETS_PATH.NOMADS_LOGOS.TEXT_AND_LOGO} alt="Logo" />
							<div className="line"></div>
							<p className="title">{translations[selectedLanguage].subImageDashboard.bannerTitle}</p>
							<div className="device-options-container">
								<a href="https://play.google.com/store" target="_blank" id="play-store-button">
									<img src={ASSETS_PATH.STORE[`PLAY_STORE_${selectedLanguage}`]} className="deviceOptions" alt="Play Store" />
								</a>
								<a href="https://www.apple.com/app-store/" target="_blank" id="app-store-button">
									<img src={ASSETS_PATH.STORE[`APP_STORE_${selectedLanguage}`]} className="deviceOptions" alt="App Store" />
								</a>
								<a href="https://www.meta.com/quest/" target="_blank" id="meta-store-button">
									<img src={ASSETS_PATH.STORE[`META_STORE_${selectedLanguage}`]} className="deviceOptions" alt="Meta Store" />
								</a>
							</div>

							<div className="buttons-container">
								<button onClick={() => setShowPopUp(false)} className="save-buttons" id="save-settings">
									{translations[selectedLanguage].subImageDashboard.closeBanner}
								</button>
							</div>
						</div>
					) : (
						""
					)}

					{success ? (
						<Alert severity="success">
							{translations[selectedLanguage].subImageDashboard.success}: {clipboard}
						</Alert>
					) : (
						""
					)}
					{isSelected && (
						<audio className={fullscreen ? "audio-player-fullscreen" : "audio-player"} controls>
							<source src={audioSrc} type="audio/mpeg" />
							Your browser does not support the audio element.
						</audio>
					)}
					<div className="menu-content" onClick={handleContentClick}>
						<p>
							<b>{translations[selectedLanguage].subImageDashboard.views}</b> : {view}
						</p>

						{createdOn && (
							<p>
								<b>{translations[selectedLanguage].subImageDashboard.createdOn}</b> : {createdOn}
							</p>
						)}

						{prompt && (
							<p>
								<b>{translations[selectedLanguage].subImageDashboard.prompt}</b> : {prompt}
							</p>
						)}
					</div>
				</div>
			</div>
		</section>
	);
};

export default SubImageDashboard;
