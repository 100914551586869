export const subImageDashboard = {
  prompt: "Solicitar",
  createdOn: "Creado en",
  success: "Succès, voici le lien fourni",
  share: "partager",
  like: "aimer",
  callToAction: "Téléchargez notre application",
  closeBanner: "Fermer",
  bannerTitle: "Choisir",
  views: "Vues"
};
